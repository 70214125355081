import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { firstValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from '../utils/globals';
import * as Sentry from '@sentry/browser';
import {
  AuthUserModel,
  CityModel,
  ComponentType,
  CountryModel,
  Gender,
  HasPermissionModel, IdentityResourceService,
  MaritalStatus,
  MembershipModel,
  OrganizationModel,
  PermissionKey,
  PermissionModel,
  PermissionResourceService,
  StoreBasicModel,
  StoreFullModel,
  UserBasicModel,
  UserProfileModel
} from '../vn-api';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class HxAuthService {
  constructor(
    private keycloakService: KeycloakService,
    private http: HttpClient,
    private permissionResourceService: PermissionResourceService,
    private identityService: IdentityResourceService,
  ) {
  }

  user!: AuthUserModel;

  setAuthUser(user: AuthUserModel) {
    this.user = user;
  }

  hasStoreMembership(groupId: number): Promise<boolean> {
    return firstValueFrom(this.permissionResourceService.storeMembershipExists(groupId)).then(res => res.hasStoreMembership);
  }

  getCitiesByBrandId(brandId: number): Promise<CityModel[]> {
    return firstValueFrom(this.http.get<CityModel[]>(`/api/vanilla/identity/brands/${brandId}/cities`));
  }

  logout() {
    this.keycloakService.logout();
  }

  getIdentity(): Promise<AuthUserModel> {
    return firstValueFrom(this.identityService.getInfo());
  }

  getOrganization(): Observable<OrganizationModel> {
    return this.http.get<OrganizationModel>('/api/vanilla/identity/organization');
  }

  getCountry(): Observable<CountryModel> {
    return this.http.get<CountryModel>('/api/vanilla/identity/country');
  }

  getUiLang(): string {
    return this.user.language?.toLowerCase() ?? 'ru';
  }

  setUiLang(lang: string): Promise<void> {
    if (lang) {
      lang = lang.toUpperCase();
    }
    return firstValueFrom(this.http.post<void>('/api/vanilla/identity/language', { language: lang }));
  }

  // достает пользователей из текущего магазина по компонентам
  getStoreUsersByComponents(params?: {
    components?: ComponentType[],
    limit?: number,
  }): Observable<UserBasicModel[]> {
    return this.http.get<UserBasicModel[]>(`/api/vanilla/identity/store-users`, {params: toHttpParams(params, true)});
  }

  saveProfile(req: {
    iin?: string;
    email?: string;
    lastname?: string;
    firstname?: string;
    patronymic?: string;
    address?: string;
    birthdate?: string; // iso date
    gender?: Gender;
    maritalStatus?: MaritalStatus;
    avatarFileId?: number;
    signFileId?: number,
  }): Observable<void> {
    return this.http.post<void>('/api/vanilla/identity/profile', req);
  }

  updatePassword(password: string): Observable<void> {
    return this.http.put<void>('/api/vanilla/identity/password', password);
  }

  getMemberships(params?: {
    includeStore?: boolean,
  }): Observable<GetMembershipsItemResponse[]> {
    return this.http.get<GetMembershipsItemResponse[]>('/api/vanilla/identity/memberships', {params: toHttpParams(params, true)});
  }

  getCityStores(): Observable<StoreBasicModel[]> {
    return this.http.get<StoreBasicModel[]>('/api/vanilla/identity/city-stores');
  }

  getComponentStores(): Promise<StoreFullModel[]> {
    return firstValueFrom(this.identityService.getComponentStores());
  }

  getUsers(params: { limit?: number, component?: ComponentType }): Observable<UserProfileModel[]> {
    return this.http.get<UserProfileModel[]>(`/api/vanilla/identity/store-user-profiles`, {params: toHttpParams(params, true)});
  }

  getPermissions(params: { permissions: PermissionKey[] }): Observable<PermissionModel[]> {
    return this.http.get<PermissionModel[]>('/api/vanilla/identity/permissions', {params: toHttpParams(params, true)});
  }

  hasPermission(key: PermissionKey, groupId?: number): Promise<HasPermissionModel> {
    return firstValueFrom(this.permissionResourceService.hasPermission(key, groupId));
  }
}

interface GetMembershipsItemResponse {
  membership: MembershipModel;
  group?: StoreBasicModel;
}

export function initKeycloak(keycloak: KeycloakService, auth: HxAuthService, env: any, tr: TranslocoService) {
  console.log('env', JSON.stringify(env));
  return () => keycloak.init({
    config: env,
    initOptions: {
      onLoad: 'login-required' // 'check-sso',
      // onLoad: 'check-sso',
      // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    },
    enableBearerInterceptor: true,
    bearerExcludedUrls: [
      '/assets',
      '/clients/public'
    ].concat(env.excludeUrls ?? []),
  })
    .then(res => keycloak.getToken())
    .then(token => {
      const params = {method: 'GET', headers: new Headers()};
      params.headers.append('Authorization', `Bearer ${token}`);
      return fetch(`/api/vn/identity/info`, params);
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('auth.error');
      }
    })
    .then(response => {
      auth.setAuthUser(response);
      tr.setActiveLang(auth.getUiLang());
      Sentry.configureScope(scope => scope.setUser({'username': auth.user.phone}));
      return true;
    }, (err: Error) => {
      console.log('err', err);
      alert('Попробуйте перезагрузить страницу Ctrl + R, если ошибка выходит то сообщите администратору');
    });
}
